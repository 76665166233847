import React from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import styled from "styled-components";
import * as Sentry from "@sentry/nextjs";

import LoadingDots from "./LoadingDots";

const prices = {
  uk: {
    id: "price_1KWNzFCSutrB4gqJZnWYenu2",
    price: "199",
    currency: "£",
  },

  us: {
    id: "price_1LBfHCCSutrB4gqJyVa4Dsmx",
    price: "199",
    currency: "$",
  },

  eur: {
    id: "price_1KxtcJCSutrB4gqJimd6x0wL",
    price: "199",
    currency: "€",
  },

  can: {
    id: "price_1LN2RjCSutrB4gqJxwdccvRY",
    price: "299",
    currency: "CA$",
  },
};

export default function CheckoutForm({
  options,
  name,
  phone,
  email,
  country,
  clientSecret,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      Sentry.captureException(
        new Error("handleSubmit : no stripe or elements")
      );
      setMessage("An unexpected error occurred. Please try again.");

      return;
    }

    setIsLoading(true);

    try {
      const cardElement = elements.getElement(CardNumberElement);

      // 1. Confirm card
      const cardConfirmation = await stripe.confirmCardSetup(clientSecret, {
        payment_method: { card: cardElement },
      });
      // console.log("cardConfirmation", cardConfirmation);

      // Guard
      if (cardConfirmation.error) {
        if (cardConfirmation.error.type === "validation_error") {
          setMessage("Card validation error.");
        } else {
          setMessage("Card Confirmation error");
        }
        setIsLoading(false);
        return;
      }
      const paymentMethodId = cardConfirmation.setupIntent.payment_method;
      // console.log("paymentMethodId", paymentMethodId);

      // 2. Do stuff
      const createResult = await fetch("/api/create", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name,
          email,
          phone,
          country,
          paymentMethodId,
        }),
      });
      const createResultJson = await createResult.json();
      // console.log("resJson", createResultJson);

      //
      if (
        createResultJson.setupIntent &&
        createResultJson.setupIntent.status === "succeeded"
      ) {
        // console.log("all good 1");
        // all good 1
        const url = "https://www.oneday.io/kick-off-call";
        //window.location = url;
        parent.postMessage({ name: "oneday_stripe_done", url }, "*");

        setMessage("All done. You will be redirected now...");
      } else {
        // console.log("try to confirm 2");
        // Confirm card
        const cardConfirmation2 = await stripe.confirmCardSetup(
          createResultJson.clientSecret
        );
        // console.log("cardConfirmation2", cardConfirmation2);

        if (
          cardConfirmation2.setupIntent &&
          cardConfirmation2.setupIntent.status === "succeeded"
        ) {
          // console.log("all good 2");
          // all good 2
          const url = "https://www.oneday.io/kick-off-call";
          //window.location = url;
          parent.postMessage({ name: "oneday_stripe_done", url }, "*");

          setMessage("All done. You will be redirected now...");
        } else {
          if (cardConfirmation2.error) {
            const error = cardConfirmation2.error;
            console.error(error);
            Sentry.captureException(new Error(`Code: ${error.code}`));
            if (
              error?.type === "card_error" ||
              error?.type === "validation_error"
            ) {
              setMessage(error.message);
            } else {
              setMessage("An unexpected error occurred.");
              Sentry.captureException(new Error("Error 1001"));
            }
          } else {
            setMessage("An unexpected error occurred.");
            Sentry.captureException(new Error("Error 1002"));
          }
        }
      }
    } catch (exception) {
      Sentry.captureException(exception);
      console.error("exception", exception);
      setMessage("An unexpected error occurred.");
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  const selectedPrice = prices[country];

  return (
    <>
      <Header>Start Your 14 day Free Trial</Header>
      <SubHeaderText1>
        Then pay {selectedPrice.currency}
        {selectedPrice.price}/month
      </SubHeaderText1>
      <SubHeaderText2>
        Cancel anytime, no payment will be taken til free trial period ends.
      </SubHeaderText2>

      <FieldRowContainer>
        <FieldContainer>
          <FieldLabel htmlFor="sign_email">Name</FieldLabel>
          <FieldInput
            type="email"
            name="sign_email"
            id="sign_email"
            value={name}
            readOnly
            disabled
          />
        </FieldContainer>
        <FieldPhoneContainer>
          <FieldLabel htmlFor="sign_email">Phone</FieldLabel>
          <FieldInput
            type="email"
            name="sign_email"
            id="sign_email"
            value={phone}
            readOnly
            disabled
          />
        </FieldPhoneContainer>
      </FieldRowContainer>

      <FieldRowContainer>
        <FieldContainer>
          <FieldLabel htmlFor="sign_email">Email</FieldLabel>
          <FieldInput
            type="email"
            name="sign_email"
            id="sign_email"
            placeholder=""
            value={email}
            readOnly
            disabled
          />
        </FieldContainer>
      </FieldRowContainer>

      <FieldRowContainer>
        <CardDetailsContainer>
          <BigInputContainer>
            <Label>card number</Label>
            <CardNumberElement options={options} />
          </BigInputContainer>
          <CardDateContainer>
            <Label>valid</Label>
            <CardExpiryElement options={options} />
          </CardDateContainer>
          <CardCvcContainer>
            <Label>cvv</Label>
            <CardCvcElement options={options} />
          </CardCvcContainer>
        </CardDetailsContainer>
      </FieldRowContainer>

      {message && <Message>{message}</Message>}

      <StripeTextContainer>
        <PadlockImage src="/img/padlock.svg" />
        <StripeText>Powered by Stripe</StripeText>
      </StripeTextContainer>

      <PrivacyText>
        At the end of your free trial, you will be charged{" "}
        {selectedPrice.currency}
        {selectedPrice.price} on a recurring basis and your subscription will
        automatically continue until canceled. You can cancel your subscription
        at any time to avoid future charges; your cancelation will be effective
        at the end of the current billing period. To cancel, fill in the form in
        the FAQ section on our website. By clicking ‘Start Free Trial’ you agree
        to the above terms, Oneday’s Terms of Service, Privacy Policy and all
        other Policies.
      </PrivacyText>
      <BottomContainer>
        <BottomText>
          After free trial you can cancel, upgrade or downgrade at anytime.
        </BottomText>
        <Button
          onClick={handleSubmit}
          disabled={isLoading || !stripe || !elements}
        >
          {isLoading ? <LoadingDots /> : "Start free trial"}
        </Button>
      </BottomContainer>
    </>
  );
}

const BottomContainer = styled.div`
  display: flex;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;
const BottomText = styled.div`
  margin-left: 16px;
  margin-right: 48px;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.04em;
  color: #000000;

  @media (max-width: 450px) {
    order: 2;

    margin-top: 15px;
    font-size: 10px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.03em;
    color: #000000;
  }
`;

const CardDetailsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border-radius: 17px;

  height: 76px;
  padding: 12px;
  @media (max-width: 450px) {
    height: 52px;
    min-height: 52px;
    padding: 18px 12px 12px 12px;
  }
`;

const InputContainer = styled.div`
  box-sizing: border-box;
`;

const BigInputContainer = styled(InputContainer)`
  flex: 1;
`;

const CardDateContainer = styled(InputContainer)`
  width: 110px;
  margin-left: 8px;
  @media (max-width: 450px) {
    width: 80px;
    margin-left: 6px;
  }
`;
const CardCvcContainer = styled(InputContainer)`
  width: 110px;
  margin-left: 8px;
  @media (max-width: 450px) {
    width: 50px;
    margin-left: 6px;
  }
`;

const Label = styled.div`
  color: #000000;
  font-size: 14px;
  line-height: 150%; /* identical to box height, or 24px */
  letter-spacing: -0.03em;
  color: #000;
  margin-bottom: 6px;

  @media (max-width: 450px) {
    display: none;
  }
`;

/* */
const Message = styled.div`
  font-size: 16px;
  color: red;
  margin-bottom: 12px;
`;

const StripeTextContainer = styled.div`
  display: flex;
  padding-left: 16px;

  @media (max-width: 450px) {
    margin-top: 15px;
    padding-left: 0;
    justify-content: center;
  }
`;
const PadlockImage = styled.img`
  margin-bottom: 20px;
`;
const StripeText = styled.div`
  margin-left: 12px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 150%; /* identical to box height, or 21px */
  letter-spacing: -0.04em;
  color: rgba(0, 0, 0, 0.5);

  @media (max-width: 450px) {
    text-align: center;

    font-size: 10px;
    text-align: center;
    letter-spacing: -0.03em;
  }
`;

/* */
const Header = styled.h1`
  font-size: 34px;
  line-height: 120%;
  letter-spacing: -0.04em;
  color: #1a1e21;

  @media (max-width: 450px) {
    font-size: 28px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.04em;
    color: #010101;
    text-align: center;
  }
`;
const SubHeaderText1 = styled.div`
  margin-top: 4px;
  font-size: 18px;
  line-height: 150%; /* identical to box height, or 27px */
  letter-spacing: -0.04em;
  color: #000000;

  @media (max-width: 450px) {
    text-align: center;
  }
`;
const SubHeaderText2 = styled.div`
  margin-top: 15px;

  font-size: 18px;
  line-height: 150%; /* or 27px */
  letter-spacing: -0.04em;
  color: #000000;
  margin-bottom: 23px;

  @media (max-width: 450px) {
    text-align: center;

    font-size: 12px;
    line-height: 128%;
    text-align: center;
    letter-spacing: -0.04em;
    color: #0b0c08;
  }
`;

const PrivacyText = styled.div`
  padding-left: 16px;
  margin-bottom: 37px;

  font-size: 14px;
  line-height: 150%; /* or 21px */
  letter-spacing: -0.04em;
  color: rgba(0, 0, 0, 0.5);

  @media (max-width: 450px) {
    padding-left: 0;
    font-size: 10px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.03em;
  }
`;
const FieldRowContainer = styled.div`
  margin-bottom: 16px;
  display: flex;

  @media (max-width: 450px) {
    flex-direction: column;
    margin-bottom: 0px;
  }
`;

const FieldContainer = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 12px;

  &.is-error label {
    color: #f1526f;
  }
  &.is-error input {
    border-color: #f1526f;
    color: #f1526f;
  }
`;

const FieldLabel = styled.label`
  z-index: 2;
  position: absolute;
  top: 12px;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  color: #000000;
  font-size: 14px;

  @media (max-width: 450px) {
    display: none;
  }
`;

const FieldInput = styled.input`
  font-family: "EuclidCircularA", sans-serif;
  outline: none;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 76px;
  margin: 0;
  padding: 25px 12px 0;
  border: 1px solid #fbfbfb;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 12px;
  background-color: #fbfbfb;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: -0.05em;

  @media (max-width: 450px) {
    height: 52px;
    padding: 0 12px 0;
    font-size: 14px;
  }

  color: #000;
  &[disabled] {
    color: rgba(0, 0, 0, 0.5);
  }
`;

const FieldPhoneContainer = styled(FieldContainer)`
  margin-left: 19px;
  @media (max-width: 450px) {
    margin-left: 0;
  }
`;
const Button = styled.button`
  font-weight: bold;

  outline: none;
  cursor: pointer;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 76px;
  border-radius: 20px;
  border: 2px solid #000;
  // box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.1),
  //   0px 41.7776px 33.4221px rgba(0, 0, 0, 0.179714),
  //   0px 22.3363px 17.869px rgba(0, 0, 0, 0.149027),
  //   0px 12.5216px 10.0172px rgba(0, 0, 0, 0.125),
  //   0px 6.6501px 5.32008px rgba(0, 0, 0, 0.100973),
  //   0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0702864);
  background-color: #000000;
  font-family: "Gilroy", "EuclidCircularA", sans-serif;
  font-size: 26px;
  line-height: 80%;
  /* identical to box height, or 21px */
  text-align: center;
  letter-spacing: -0.04em;
  color: #ffffff;
  -webkit-transition: border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out, color 0.3s ease-in-out,
    -webkit-box-shadow 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  -o-transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out, color 0.3s ease-in-out,
    -webkit-box-shadow 0.3s ease-in-out;

  &:hover {
    background-color: #3c3c3c;
    -webkit-transition: border-color 0.3s ease-in-out,
      background-color 0.3s ease-in-out, color 0.3s ease-in-out,
      -webkit-box-shadow 0.3s ease-in-out;
    transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out,
      color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
    -o-transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out,
      background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out,
      background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out,
      background-color 0.3s ease-in-out, color 0.3s ease-in-out,
      -webkit-box-shadow 0.3s ease-in-out;
  }

  &[disabled] {
    pointer-events: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #969696;
    background-color: #969696;
    color: rgba(255, 255, 255, 0.3);
    -webkit-transition: border-color 0.3s ease-in-out,
      background-color 0.3s ease-in-out, color 0.3s ease-in-out,
      -webkit-box-shadow 0.3s ease-in-out;
    transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out,
      color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
    -o-transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out,
      background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out,
      background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out,
      background-color 0.3s ease-in-out, color 0.3s ease-in-out,
      -webkit-box-shadow 0.3s ease-in-out;
  }
`;
