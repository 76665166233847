import React from 'react'
import styled, { keyframes } from 'styled-components'

export default function LoadingDots({ isBlackColor }) {
  return (
    <Container>
      <Dot isBlackColor={isBlackColor} />
      <Dot isBlackColor={isBlackColor} />
      <Dot isBlackColor={isBlackColor} />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  alig-items: center;
  justify-content: center;
`

const loadingFade = keyframes`
0% {
  opacity: 0;
}
50% {
  opacity: 0.8;
}
100% {
  opacity: 0;
}
`

const Dot = styled.div`
  float: left;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: ${(props) => (props.isBlackColor ? 'black' : 'white')};

  border-radius: 50%;
  opacity: 0;

  animation: ${loadingFade} 1s infinite;

  &:nth-child(1) {
    animation-delay: 0s;
  }

  &:nth-child(2) {
    animation-delay: 0.1s;
  }

  &:nth-child(3) {
    animation-delay: 0.2s;
  }

  &:nth-child(4) {
    animation-delay: 0.3s;
  }
`
