import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import styled from "styled-components";
import * as Sentry from "@sentry/nextjs";

import CheckoutForm from "../components/CheckoutForm";

const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
);

export default function App() {
  const [clientSecret, setClientSecret] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [internalLoading, setInternalLoading] = React.useState(true);

  React.useEffect(async () => {
    const params = new URL(window.location.href).searchParams;

    const name = params.get("name");
    const email = params.get("email");
    const phone = params.get("phone");
    const country = params.get("country");

    setName(name);
    setEmail(email);
    setPhone(phone);
    setCountry(country);

    try {
      const res = await fetch("/api/prepare_payment", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
      const data = await res.json();
      setClientSecret(data.clientSecret);

      const size = window.document.documentElement.scrollHeight + 5;
      parent.postMessage({ name: "oneday_stripe_loaded", size }, "*");
    } catch (exc) {
      Sentry.captureException(exc);
      console.error("Exception", exc);
    }
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const style = {
    base: {
      iconColor: "#000000",
      color: "#000000",
      fontWeight: "500",
      fontFamily: "Gilroy, EuclidCircularA, sans-serif",
      fontSize: "14px",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "rgba(0, 0, 0, 0.4)",
      },
    },
    invalid: {
      iconColor: "#f1526f",
      color: "#f1526f",
    },
  };
  const options = {
    clientSecret,
    appearance,
    style,
  };

  setTimeout(() => {
    setInternalLoading(false);
  }, 300);

  return (
    <Main>
      {!clientSecret && !internalLoading && (
        <LoaderContainer>
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </LoaderContainer>
      )}
      {clientSecret && !internalLoading && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            options={options}
            name={name}
            email={email}
            phone={phone}
            country={country}
            clientSecret={clientSecret}
          />
        </Elements>
      )}
    </Main>
  );
}

const Main = styled.div`
  padding: 44px 0px 20px 0px;
  @media (max-width: 450px) {
    padding: 0px;
  }
`;
const LoaderContainer = styled.div`
  width: 100%;
  flex: 1;
  justify-content: center;
  display: flex;
`;
